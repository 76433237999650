import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/inventory/general'

const getAssetGeneral = () => axios.get(`${endpoint}`)
const updateAssetGeneral = (ctx, data) => axios.patch(`${endpoint}`, data)
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  getAssetGeneral,
  updateAssetGeneral,
}
