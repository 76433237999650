<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import assetGeneralModule from '@/store/settings/inventory/asset-general'

export default {
  name: 'AssetGeneral',
  created() {
    this.$emit('updateMenu', 'settings-asset-general')
  },
  setup() {
    const MODULE_NAME = 'asset-general'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, assetGeneralModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>
